export const environment = {
    production: false,
    development: false,
    version: '1.0.0',
    name: 'Elevator Call Manager',
    localStorageName: 'kecm',
    mockService: false,
    api: 'https://kecapi-test-dps.kone.com/api/kecmgr',
    qrCodeUrl: 'https://keca-onboard-test-dps.kone.com',
    aws: {
        aws_project_region: 'eu-west-1',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_J1QeEXJql',
        aws_user_pools_web_client_id: '4q6f472gef1sv41kmphjjuj4oa',
        oauth: {
            domain: 'resiflow-test.auth.eu-west-1.amazoncognito.com',
            scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://kecmgr-test-dps.kone.com/',
            redirectSignOut: 'https://kecmgr-test-dps.kone.com/',
            responseType: 'code',
        },
        provider: 'KONE-AD-SSO-dev-test-IdP',
    },
};
